import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { genHash } from "./utils/index"
import ReactGA from "react-ga"
import axios from "axios"
import useGlobalState from "./utils/useGlobalState"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
// check if publication is restricted lacted wp api
// if restricted -> prompt login
// if restricted & token -> login without username and password
const Restrict = ({ children }) => {
  const {
    wordpressAcfOptions: {
      options: { password, is_active, product_id },
    },
  } = useStaticQuery(PASSWORD)
  const [_, setState] = useGlobalState() // eslint-disable-line no-unused-vars
  const [pass, setPassword] = React.useState("")

  function isPremium() {
    var endpoint =
      process.env.GATSBY_LACTED_URL +
      "/wp-json/wc/v2/products/" +
      product_id +
      "/?consumer_key=ck_f2739f70318358a2515989f6f096af2b8ff8a9fd&consumer_secret=cs_4f8ca4ac27a408ec0fddba33397fb36ab414ed41"

    axios
      .get(endpoint)
      .then(({ data }) => {
        /* eslint-disable eqeqeq */
        if (data.price == 0)
          setState(p => ({ ...p, hasAccess: true, hash: genHash() }))
        /* eslint-enable eqeqeq */
      })
      .catch(e => console.error({ e, endpoint }))
  }

  React.useEffect(() => {
    isPremium()
    ReactGA.initialize(process.env.GATSBY_GOOGLE_ID)
    var search = window?.location?.search
    if (search && search.includes("?token=")) {
      var token = search.replace("?token=", "")
      setState(p => ({ ...p, token }))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // return children
  if (is_active || pass === password) return children

  return (
    <Container>
      <Box>
        <h1>Under Development</h1>
        <input
          type="text"
          value={pass}
          onChange={({ target }) => setPassword(target.value)}
        />
      </Box>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
`

const Box = styled.div`
  border-radius: 6px;
  box-shadow: 0 1px 3px 0px #929292;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 100%;
  }

  input {
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid grey;
  }
`

const PASSWORD = graphql`
  {
    wordpressAcfOptions {
      options {
        password
        is_active
        product_id
      }
    }
  }
`

export default Restrict

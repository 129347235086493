import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import firebase from "../components/firebase/index"

import createPersistedState from "use-persisted-state"
const useAuthState = createPersistedState("global")

function useGlobalState() {
  var {
    wordpressAcfOptions: {
      options: { product_id },
    },
  } = useStaticQuery(CONFIG)
  var [state, setState] = useAuthState({ [product_id]: {} })
  // return [state, setState]

  function update(value) {
    setState(prev => {
      var focus = prev && prev[product_id] ? prev[product_id] : {}
      if (typeof value === "function") {
        var updated = value(focus)
        var payload = { ...prev, [product_id]: updated }
        return payload
      }
      return { ...prev, [product_id]: value }
    })
  }

  var handleSnap = snap => {
    var data = { id: snap.id, ...snap.data() }
    var favs = data[product_id] || []

    update(p => ({ ...p, favs }))
  }

  var focus = state && state[product_id] ? state[product_id] : {}
  var uid = focus?.user?.id

  React.useEffect(
    () =>
      uid
        ? firebase
            .firestore()
            .doc("user-favs/" + uid)
            .onSnapshot(handleSnap)
        : () => {},
    [uid] // eslint-disable-line react-hooks/exhaustive-deps
  )

  var data = state && state[product_id] ? state[product_id] : {}

  return [data, update]
}

export default useGlobalState

const CONFIG = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id,
      }
    }
  }
`

const JsSearch = require("js-search")

const isArray = xs => Array.isArray(xs)
const listHasElements = xs => isArray(xs) && xs.length > 0

// partially applies arguments to function and returns a function waiting for the rest of the arguments
const curry = (f, arr = []) => (...args) =>
  (a => (a.length === f.length ? f(...a) : curry(f, a)))([...arr, ...args])

const getPath = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
// safly acces path on object
const path = curry(getPath)

function strip(html) {
  if (typeof window === "undefined") return html
  var doc = new DOMParser().parseFromString(html, "text/html")
  return doc.body.textContent || ""
}

const wrapHighlight = (text, phrase) => {
  var tokenHighlighter = new JsSearch.TokenHighlighter()

  if (!text || !phrase) return ""
  return tokenHighlighter.highlight(text, phrase.split(" "))
}

// helpers to determine block type

const HEADING = "WordPressAcf_heading"
const BODY = "WordPressAcf_body"
const IMAGE = "WordPressAcf_image"

const entityIs = type => ({ __typename }) => type === __typename
const isHeading = entityIs(HEADING)
const isBody = entityIs(BODY)
const isImage = entityIs(IMAGE)

function hash(string) {
  var hash = 0
  if (string.length === 0) {
    return hash
  }
  for (var i = 0; i < string.length; i++) {
    var char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

function genHash() {
  var d = new Date()
  return hash("" + d.getDay() + d.getMonth() + d.getFullYear())
}

function hashValid(hash) {
  return hash === genHash()
}

module.exports = {
  listHasElements,
  path,
  hash,
  wrapHighlight,
  isHeading,
  isBody,
  isImage,
  genHash,
  hashValid,
  strip,
}

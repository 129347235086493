import React, { useState, createContext, useContext } from "react"
import styled from "styled-components"

const ModalContext = createContext()

// General Design Pattern ideas
// ============================
// The same modal is used accross the site with new content being injected into it
// for specific use cases. It's iterface is described bellow.
// the useModal hook allows:
//  - managing open/closed state
//  - setting inner component
//  - customizing styles with the setConfig function made available

// Modifying the customizable properties
// =====================================
// Currently it caters for customizing the width and padding but to add to this,
// do so using the config object whos values gets passed to the styled component

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState({
    width: "100%",
    padding: "0px",
    height: "110%",
  })
  const [component, setComponent] = useState(<Container />)

  const openFn = () => setOpen(true)
  const closeFn = () => {
    setOpen(false)
    setComponent(<Container />)
  }

  return (
    <ModalContext.Provider
      value={{
        open,
        openFn,
        closeFn,
        component,
        setComponent,
        config,
        setConfig,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Container = styled.div``

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

/**
 * @returns {open,
        openFn,
        closeFn,
        component,
        setComponent,
        config,
        setConfig}
 */
export function useModal() {
  return useContext(ModalContext)
}

export default ModalProvider

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bookmarks-jsx": () => import("./../../../src/pages/bookmarks.jsx" /* webpackChunkName: "component---src-pages-bookmarks-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */)
}


const { lighten, complement, readableColor } = require("polished")

// const cool = ["#390067"]

// color => {shade:[background,text]}
const scafholdColors = (color, contrast = false) => {
  const amount = 0.07
  const range = 9
  const mid = Math.ceil(range / 2)

  return Array.from({ length: range }, (v, k) => k + 1).reduce((a, c) => {
    const newColor = lighten((mid - c) * amount, color)
    const oposite = complement(newColor)
    const readable = readableColor(newColor)
    return { ...a, [c + "00"]: [newColor, contrast ? oposite : readable] }
  }, {})
}

//======================
// Structure of colors
//======================
// variable name = color accesor
// key = shade of color
// value = [background, text]
// =====================
// add a color group, add the variable to the colors
// object and access thought the project with the
// colors function listed at the bottom
// =====================

// const exampleColor = {
//   //shade  background  text
//   100: ["#AFFCC9", "#1B1A1A"],
// }

var blue1 = {
  100: ["#3933FF", "#FFFFFF"],
  200: ["#0700FA", "#FFFFFF"],
  300: ["#0700E0", "#FFFFFF"],
  400: ["#0600C7", "#FFFFFF"],
  500: ["#0500AB", "#FFFFFF"],
  600: ["#040094", "#FFFFFF"],
  700: ["#04007A", "#FFFFFF"],
  800: ["#030061", "#FFFFFF"],
  900: ["#020047", "#FFFFFF"],
}
var blue2 = {
  100: ["#6142FF", "#FFFFFF"],
  200: ["#4F2EFF", "#FFFFFF"],
  300: ["#2900FF", "#FFFFFF"],
  400: ["#2600EB", "#FFFFFF"],
  500: ["#2200D4", "#FFFFFF"],
  600: ["#1F00C2", "#FFFFFF"],
  700: ["#1C00AD", "#FFFFFF"],
  800: ["#180094", "#FFFFFF"],
  900: ["#140080", "#FFFFFF"],
}
var blue3 = {
  100: ["#1A91FF", "#000000"],
  200: ["#0A89FF", "#000000"],
  300: ["#0082FA", "#000000"],
  400: ["#007AEB", "#000000"],
  500: ["#006FD6", "#FFFFFF"],
  600: ["#0067C7", "#FFFFFF"],
  700: ["#005FB8", "#FFFFFF"],
  800: ["#0057A8", "#FFFFFF"],
  900: ["#004F99", "#FFFFFF"],
}
var blue4 = {
  100: ["#66CBFF", "#000000"],
  200: ["#4CC3FF", "#000000"],
  300: ["#33BAFF", "#000000"],
  400: ["#1AB2FF", "#000000"],
  500: ["#00A9FF", "#000000"],
  600: ["#0098E6", "#000000"],
  700: ["#0087CC", "#000000"],
  800: ["#0076B3", "#000000"],
  900: ["#006599", "#FFFFFF"],
}

var green1 = {
  100: ["#AFFCC9", "#1B1A1A"],
  200: ["#89FCB0", "#1B1A1A"],
  300: ["#66FD99", "#1B1A1A"],
  400: ["#46FB84", "#1B1A1A"],
  500: ["#00FF57", "#1B1A1A"],
  600: ["#07EA54", "#1B1A1A"],
  700: ["#0CDC53", "#1B1A1A"],
  800: ["#0FCC4F", "#1B1A1A"],
  900: ["#11BB4B", "#1B1A1A"],
}

var green2 = {
  100: ["#00FF00", "#000000"],
  200: ["#00EB00", "#000000"],
  300: ["#00DB00", "#000000"],
  400: ["#00CC00", "#000000"],
  500: ["#00BE00", "#000000"],
  600: ["#00A800", "#000000"],
  700: ["#009900", "#000000"],
  800: ["#008A00", "#FFFFFF"],
  900: ["#007500", "#FFFFFF"],
}
var green3 = {
  100: ["#00D600", "#000000"],
  200: ["#00C200", "#000000"],
  300: ["#00B300", "#000000"],
  400: ["#009E00", "#000000"],
  500: ["#008B00", "#000000"],
  600: ["#007A00", "#000000"],
  700: ["#006B00", "#000000"],
  800: ["#005C00", "#FFFFFF"],
  900: ["#004D00", "#FFFFFF"],
}

var purple1 = {
  100: ["#C480FF", "#000000"],
  200: ["#B866FF", "#000000"],
  300: ["#A84DFF", "#000000"],
  400: ["#9A33FF", "#FFFFFF"],
  500: ["#8900FF", "#FFFFFF"],
  600: ["#7B00E6", "#FFFFFF"],
  700: ["#6E00CC", "#FFFFFF"],
  800: ["#6000B3", "#FFFFFF"],
  900: ["#520099", "#FFFFFF"],
}
var purple2 = {
  100: ["#7D38FF", "#FFFFFF"],
  200: ["#6C1FFF", "#FFFFFF"],
  300: ["#5C05FF", "#FFFFFF"],
  400: ["#5100EB", "#FFFFFF"],
  500: ["#4900D3", "#FFFFFF"],
  600: ["#4000B8", "#FFFFFF"],
  700: ["#37009E", "#FFFFFF"],
  800: ["#2E0085", "#FFFFFF"],
  900: ["#230066", "#FFFFFF"],
}
var orange = {
  100: ["#FFA761", "#000000"],
  200: ["#FF9947", "#000000"],
  300: ["#FF8D33", "#000000"],
  400: ["#FF7F1A", "#000000"],
  500: ["#FF7100", "#000000"],
  600: ["#EB6800", "#000000"],
  700: ["#DB6100", "#000000"],
  800: ["#C75800", "#000000"],
  900: ["#B34F00", "#FFFFFF"],
}
var yellow = {
  100: ["#FFDA66", "#000000"],
  200: ["#FFD44C", "#000000"],
  300: ["#FFCD33", "#000000"],
  400: ["#FFC71A", "#000000"],
  500: ["#FFC100", "#000000"],
  600: ["#E6AE00", "#000000"],
  700: ["#CC9A00", "#000000"],
  800: ["#BD8F00", "#000000"],
  900: ["#AD8300", "#000000"],
}
var red = {
  100: ["#FF7575", "#000000"],
  200: ["#FF5C5C", "#000000"],
  300: ["#FF4747", "#000000"],
  400: ["#FF3838", "#000000"],
  500: ["#FF0000", "#000000"],
  600: ["#E00000", "#FFFFFF"],
  700: ["#CC0000", "#FFFFFF"],
  800: ["#B30000", "#FFFFFF"],
  900: ["#940000", "#FFFFFF"],
}

var grey1 = {
  100: ["#F9F9FA", "#1C1D1F"],
  200: ["#F4F4F6", "#1C1D1F"],
  300: ["#EEEEF1", "#1C1D1F"],
  400: ["#E9E9ED", "#1C1D1F"],
  500: ["#E2E2E7", "#1C1D1F"],
  600: ["#DDDDE3", "#1C1D1F"],
  700: ["#D8D8DF", "#1C1D1F"],
  800: ["#D2D2DA", "#1C1D1F"],
  900: ["#CDCDD6", "#1C1D1F"],
}

var grey2 = {
  100: ["#5D636F", "#FFFFFF"],
  200: ["#565C67", "#FFFFFF"],
  300: ["#515761", "#FFFFFF"],
  400: ["#4D525C", "#FFFFFF"],
  500: ["#474C55", "#FFFFFF"],
  600: ["#434851", "#FFFFFF"],
  700: ["#3F434B", "#FFFFFF"],
  800: ["#383C43", "#FFFFFF"],
  900: ["#31343A", "#FFFFFF"],
}

// Add your color here
// ========vv=========

var colors = {
  "blue-01": { ...blue1, complement: "blue-02" },
  "blue-02": { ...blue2, complement: "blue-02" },
  "blue-03": { ...blue3, complement: "blue-02" },
  "blue-04": { ...blue4, complement: "blue-02" },
  "purple-01": { ...purple1, complement: "blue-02" },
  "purple-02": { ...purple2, complement: "blue-02" },
  "green-01": { ...green1, complement: "purple-01" },
  "green-02": { ...green2, complement: "green-03" },
  "green-03": { ...green3, complement: "green-02" },
  "grey-01": { ...grey1, complement: "grey-02" },
  "grey-02": { ...grey2, complement: "grey-01" },

  orange: { ...orange, complement: "red" },
  yellow: { ...yellow, complement: "orange" },
  red: { ...red, complement: "yellow" },
}

function getPallete(name) {
  return colors[name]
}

const getTheme = (color, contrast = false) => {
  const colors = getPallete(color)
    ? getPallete(color)
    : scafholdColors(color, contrast)

  const getColor = (shade = 500, isText = false) =>
    colors[shade][isText ? 1 : 0]

  return {
    colors,
    getColor,
  }
}

export { getPallete, scafholdColors, colors, getTheme }

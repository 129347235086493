import React, { useState, useContext, createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getPallete, scafholdColors, colors as allColors } from "./colors"
import { complement } from "polished"
import useGlobalState from "../../utils/useGlobalState"

const ThemeContext = createContext()

const ThemeManager = ({ children }) => {
  const {
    wordpressAcfOptions: {
      options: { theme: PALLETE },
    },
  } = useStaticQuery(OPTIONS)

  const [auth, setAuth] = useGlobalState()
  var color = auth?.theme
  // const [auth, setAuth] = useAuthState({ hasAccess: false })

  const getTheme = (color, contrast = false) => {
    const colors = getPallete(color)
      ? getPallete(color)
      : scafholdColors(color, contrast)

    const getColor = (shade = 500, isText = false) =>
      colors[shade][isText ? 1 : 0]

    return {
      color,
      colors,
      getColor,
    }
  }

  const [theme, setTheme] = useState(getTheme(auth?.theme || PALLETE))
  const [altTheme, setAltTheme] = useState(getTheme("teal"))

  React.useEffect(() => {
    if (theme?.color !== color) updateTheme(color)
  }, [color, theme]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateAltTheme(theme.colors.complement || complement(theme.colors[500][0]))
  }, [theme]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateTheme = (color = PALLETE, contrast = false) => {
    setAuth(p => ({ ...p, theme: color }))
    setTheme(getTheme(color, contrast))
  }

  const updateAltTheme = (color = PALLETE, contrast = false) =>
    setAltTheme(getTheme(color, contrast))

  const predifined = Object.keys(allColors)

  return (
    <ThemeContext.Provider
      value={{ theme, updateTheme, altTheme, updateAltTheme, predifined }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

function useTheme() {
  return useContext(ThemeContext)
}

const OPTIONS = graphql`
  query Options {
    wordpressAcfOptions {
      options {
        front_end_url
        publication_name
        publication_overview
        theme
        publication_icon {
          slug
        }
      }
    }
  }
`

export { useTheme }
export default ThemeManager

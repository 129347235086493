import React, { createContext } from "react"
import ModalProvider from "./src/components/layout/modal/modal-provider"
import ThemeManager from "./src/components/layout/theme-manager"
import Restrict from "./src/restrict"
import FirebaseProvider from "./src/components/firebase/Provider"

const LocationContext = createContext()

const ContextProvider = props => {
  const { element } = props

  console.warn = console.error = () => {}

  return (
    <LocationContext.Provider value={props}>
      <FirebaseProvider>
        <ThemeManager>
          <Restrict>
            {/* <Auth> */}
            <ModalProvider>{element}</ModalProvider>
            {/* </Auth> */}
          </Restrict>
        </ThemeManager>
      </FirebaseProvider>
    </LocationContext.Provider>
  )
}

export default ContextProvider

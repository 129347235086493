import React, { createContext } from "react"

import useGlobalState from "../../utils/useGlobalState"

const Context = createContext()

function FirebaseProvider({ children, props }) {
  const [state] = useGlobalState()

  return <Context.Provider value={{ state }}>{children}</Context.Provider>
}

export default FirebaseProvider
